@use '../../../styles/common/variables.scss' as *;
@use '../../../styles/common/resolutions.scss' as *;

.PageLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__progressWrap {
    margin: 16px;
  }

  .Logo {
    max-width: 150px;
    color: $black_logo;
  }
}
