@use '../../styles/common/resolutions.scss' as *;
@use '../../styles/common/variables.scss' as *;

.MenuItem {
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 $gray_DE;

  &__Text {
    flex-grow: 1;
    font-family: $font-family;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.3px;
    color: $main-font-color;
  }

  &__Arrow {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    background: url('#{$staticUrlBase}img/svg/right-arrow-2.svg') center
      no-repeat;
  }

  &__MakeIcon {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.Select .MenuItem__MakeIcon {
  display: none;
}
