@import './variables.scss';

@font-face {
  font-family: 'Interstate';
  font-display: swap;
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Regular.eot');
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Regular.woff2')
      format('woff2'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Regular.woff') format('woff'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Light';
  font-display: swap;
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Light.eot');
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Light.woff2') format('woff2'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Light.woff') format('woff'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Bold';
  font-display: swap;
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Bold.eot');
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Bold.woff2') format('woff2'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Bold.woff') format('woff'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Interstate-Black';
  font-display: swap;
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Black.eot');
  src: url('#{$staticUrlBase}anms/fonts/Interstate-Black.woff2') format('woff2'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Black.woff') format('woff'),
    url('#{$staticUrlBase}anms/fonts/Interstate-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
