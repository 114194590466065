@import 'variables';
@import 'resolutions';
@import 'fonts';
@import 'common';
@import 'typography';

* {
  box-sizing: border-box;
}

html {
  position: relative;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  max-width: 100%;
  font-family: $default-font-family;
  color: $main-font-color;
}

body {
  overflow-x: hidden;
  background-image: radial-gradient(circle at 0 0, white, #f4f2ff);
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: -0.1px;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
p,
body,
ul,
ol {
  margin: 0;
}

h1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: bold;
  color: $black_19;
}

h2 {
  font-size: 48px;
  line-height: 64px;
  font-weight: bold;
  color: $black_19;
}

h3 {
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
}

h4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

h5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

h6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

a {
  color: $link_blue;
  text-transform: none;
  text-decoration: underline;
}

.subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.subtitle2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}

.body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.body2 {
  @include body2;
}

.link {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.link2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.link-colored {
  color: $link_blue;
  line-height: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.overline {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 1.2em;
}

.disclaimer {
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: none;
}
