@use '../../../styles/common/variables.scss' as *;
@use '../../../styles/common/resolutions.scss' as *;

.GlobalSnackbar {
  & > div {
    background-color: $snack_bg;
  }

  .MuiSnackbarContent-message {
    font-family: $font-family;
    font-size: 13px;
    line-height: 19px;
  }
}
