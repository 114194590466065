@use '../../styles/common/resolutions.scss' as *;
@use '../../styles/common/variables.scss' as *;

.Button {
  &--link {
    padding: 0;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }

  &__circularProgress {
    margin: 0 auto;
    position: absolute;
    color: white;
    height: 25px !important;
    width: 25px !important;
  }

  &__textHidden {
    visibility: hidden;
  }
}

.CommonButton {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
  padding-right: 2px;
  padding-left: 2px;
  height: 48px;
  color: $link_blue;

  &--danger {
    color: $danger;
  }
}
