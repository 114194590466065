@use '../../../../../styles/common/variables.scss' as *;
@use '../../../../../styles/common/resolutions.scss' as *;

.FleetContactSupport {
  position: fixed;
  bottom: 25px;
  right: 25px;

  button {
    background-color: $primary;
  }
}
