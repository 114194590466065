@use '../../styles/common/resolutions.scss' as *;
@use '../../styles/common/typography.scss' as *;

.AppTitle {
  @include header4;
  margin-bottom: 16px;
  @include mdAndUp {
    margin-bottom: 32px;
    @include header3;
  }
}
