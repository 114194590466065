@use '../../styles/common/resolutions.scss' as *;
@use '../../styles/common/variables.scss' as *;

.FormControl {
  position: relative;

  &:hover .FormControl__notchedOutline {
    border-color: $primary;
  }

  &--hasValue {
    .FormControl__InputLabel.FormControl__InputLabel--hasLabelIcon {
      transform: translate(14px, -9px) scale(0.75);
      background-color: white;
      padding: 0 4px;
    }
    .FormControl__InputLabel--disabled.MuiInputLabel-shrink {
      background-color: transparent;
    }
  }

  &__input {
    transition: 0.3s ease padding-left;
    font-weight: 700;
  }

  &--focused .FormControl__InputLabel--shrink {
    color: $primary;
  }

  &--error .FormControl__InputLabel {
    color: $red;
  }

  &__Input.FormControl__Input--disabled {
    border-radius: 4px;
    background-color: rgba(63, 63, 63, 0.08);
  }

  &__Input--focused fieldset {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);
    background: transparent;
  }

  &__InputLabel {
    font-family: $default-font-family;
    font-weight: 700;
    color: rgba(118, 118, 118, 0.5);
    &--hasLabelIcon:not(.FormControl__InputLabel--shrink) {
      transform: translate(40px, 16px) scale(1);
    }
    &--shrink {
      background-color: white;
      padding: 0 4px;
    }
  }

  &__Input--hasLabelIcon {
    .FormControl__input {
      padding-left: 38px;
    }
    &.FormControl__Input--focused,
    &.FormControl__Input--hasValue {
      .FormControl__input {
        padding-left: 14px;
      }
    }
  }

  &__InputLabel.FormControl__InputLabel--disabled {
    color: rgba(118, 118, 118, 0.5);
  }

  &__LabelIcon {
    color: $gray_DE;
    position: absolute;
    top: 27px;
    left: 8px;
  }
}
