@use '../../styles/common/variables.scss' as *;
@use '../../styles/common/resolutions.scss' as *;

.CarSelector {
  margin: 24px auto 0;
  position: relative;
  z-index: 10;

  &__BaseButton {
    width: 100%;

    @include lgAndUp {
      width: 192px;
    }
  }

  &__gridItem {
    width: 100%;
  }

  &--homePage {
    .CarSelector__BaseButton {
      width: 100%;
    }
    .FormControl__notchedOutline {
      border-color: white;
    }

    .FormControl__Input {
      color: white;
    }

    .FormControl {
      color: white;
      input {
        color: white;
      }
    }

    .FormControl__InputLabel {
      color: white;

      &--shrink {
        background-color: #2b323d;
      }
    }

    $has-value-color: #474d54;
    .FormControl--hasValue .FormControl__InputLabel--shrink {
      color: $has-value-color;
    }

    .FormControl__Input--hasValue {
      & + .FormControl__InputLabel--shrink {
        color: $has-value-color;
      }

      .FormControl__notchedOutline {
        border: solid 1px $has-value-color;
      }

      .Select__DropDownIcon {
        color: $has-value-color;
      }
    }

    $focused-color: $primary;
    .FormControl--focused {
      .FormControl__InputLabel--shrink {
        color: $focused-color;
      }

      .FormControl__notchedOutline {
        border: solid 1px $focused-color;
      }

      .Select__DropDownIcon {
        color: $focused-color;
      }
    }

    .FormControl__Input--focused + .FormControl__InputLabel--shrink {
      color: $focused-color;
    }

    // disabled styles for inputs
    $disabled-select-background: rgba(0, 0, 0, 0.24);
    $disabled-outline-color: rgba(0, 0, 0, 0.24);
    $disabled-color: rgba(118, 118, 118, 0.48);
    .FormControl__Input--disabled {
      background-color: $disabled-select-background;
      color: $disabled-color;

      & .Select__DropDownIcon,
      & ~ .FormControl__InputLabel,
      & ~ .FormControl__LabelIcon {
        color: $disabled-color;
      }

      .FormControl--notchedOutline {
        border: solid 1px $disabled-outline-color;
      }
    }

    .CarSelector__BaseButton:disabled {
      background-color: rgba(118, 118, 118, 0.24);
      color: rgba(118, 118, 118, 0.48);
    }

    .CarSelector__grid {
      justify-content: center;
    }
  }
}

@include mobileOnly {
  .CarSelector {
    margin-top: 20px;
  }
}
