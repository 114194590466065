@use '../../styles/common/resolutions.scss' as *;
@use '../../styles/common/variables.scss' as *;

.Select {
  font-family: $default-font-family;
  font-weight: 700;
  background: transparent;

  &:focus {
    background: transparent;
  }

  &__DropDownIcon {
    right: 14px;
    color: $gray_DE;
  }

  &__Menu {
    max-height: 340px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate3d(0, 0, 0);
  }

  &__MenuItem {
    border-top: 1px solid $gray_DE;
    color: $main-font-color;
    font-weight: 500;
    padding: 12px 16px;
    &:first-child {
      border-top: none;
    }
  }

  &__MenuList--repairsSearch {
    padding-top: 0;
    padding-bottom: 0;
    background-color: $gray_FA;
  }

  &--focused + .FormControl__InputLabel--shrink {
    color: $primary;
  }
}
