@import 'typography';

.noscroll {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.LayoutWrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #ffffff;
  overflow-x: hidden;

  &--withHeaderPadding {
    padding-top: 75px;

    @include mdAndUp {
      padding-top: 73px;
    }

    @include lgAndUp {
      padding-top: 88px;
    }
  }
}

.content-wrapper-without-actions-box {
  flex: 1 0 auto;
}

.fullPageAbsolute {
  position: absolute;
  height: 100%;
  width: 100%;
}

.container {
  margin-right: auto;
  margin-left: auto;
  width: $container-mobile;
  padding-left: $grid-gutter-width * 2;
  padding-right: $grid-gutter-width * 2;

  &--wide {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include mdAndUp {
    max-width: $container-tablet;
    padding-left: 64px;
    padding-right: 64px;

    &--wide {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @include lgAndUp {
    max-width: $container-desktop;
    padding-left: ($desktopColumnGap + $desktopColumnSize) * 3 + 46px;
    padding-right: ($desktopColumnGap + $desktopColumnSize) * 3 + 46px;

    &--wide {
      padding-left: 133px;
      padding-right: 133px;
    }
  }
}

.container-lg {
  margin-right: auto;
  margin-left: auto;
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  @include mobileOnly {
    width: $container-mobile;
    padding-left: $grid-gutter-width * 2;
    padding-right: $grid-gutter-width * 2;
  }
  @include tabletOnly {
    width: $container-tablet;
    padding-left: 40px;
    padding-right: 40px;
  }
  @include desktopOnly {
    width: $container-desktop;
  }
}

.PageTitle {
  &__title {
    font-family: $font-family;
    @include header4;

    line-height: 1.08;
    letter-spacing: -0.5px;
    color: $gray_3F;
    margin: 32px 0 8px 0;

    @include mdAndUp {
      font-size: 30px;
      line-height: 1.07;
      margin: 48px 0 8px;
    }

    &--vin {
      margin: 32px 0 24px;

      @include mdAndUp {
        margin: 48px 0 24px;
      }
    }

    &--plate {
      margin: 32px 0 24px;

      @include mdAndUp {
        margin: 48px 0 24px;
      }
    }
  }

  &__subtitle {
    font-family: $font-family;
    @include subtitle2;
    line-height: 1.36;
    letter-spacing: -0.1px;
    color: $gray_76;
    margin: 0 0 24px;
    max-width: 490px;

    @include mdAndUp {
      font-size: 17px;
      line-height: 1.41;
    }

    &--carDetails {
      margin: 0;

      span {
        font-weight: 700;
      }
    }
  }

  .CarSelector__submit {
    margin: 16px 0 0;
    min-width: 192px;
  }
}

.MainActionButton {
  width: 100%;
  min-width: 192px;

  @include lgAndUp {
    width: auto;
    padding: 16px;
  }

  &--location {
    margin-top: 16px;
  }
}

// here are some ad hoc rules to fix Safari font color issue fix
.MuiButton-containedPrimary .MuiButton-label {
  will-change: any;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: $white;
  -webkit-text-fill-color: $white;
}

.MuiButton-containedPrimary.Mui-disabled .MuiButton-label {
  display: block;
  color: rgba(118, 118, 118, 0.48);
  -webkit-text-fill-color: rgba(118, 118, 118, 0.48);
}

.MuiButton-containedPrimary.CancelButton .MuiButton-label {
  color: $red;
  -webkit-text-fill-color: $red;
}

.MuiButton-containedPrimary.CancelButton.Mui-disabled .MuiButton-label {
  color: rgba(118, 118, 118, 0.48);
  -webkit-text-fill-color: rgba(118, 118, 118, 0.48);
}

.publicMechanics__content .MuiButton-containedPrimary .MuiButton-label {
  color: $white;
  -webkit-text-fill-color: $white;

  @include lgAndUp {
    color: $primary;
    -webkit-text-fill-color: $primary;
  }
}

.actions-box-content {
  justify-content: flex-end;
  align-items: center;
  &.center {
    justify-content: center;
  }
  &.full-width {
    width: 100%;
  }
  &.left {
    justify-content: flex-start;
  }
  display: flex;
  @include mobileOnly {
    flex-direction: column-reverse;
  }
}

.full-width-section-content {
  justify-content: flex-start;
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
  display: flex;
  @include mobileOnly {
    flex-direction: column-reverse;
  }
}

.mar-8-top {
  margin: 8px 0 0 0;
}

.mar-8-left {
  margin: 0 0 0 8px;
}

.mar-8-right {
  margin: 0 8px 0 0;
}

.mar-8-bottom {
  margin: 0 0 8px 0;
}

.pad-8-right {
  padding: 0 8px 0 0;
}

.pad-8-bottom {
  padding: 0 0 8px 0;
}

.pad-8-top {
  padding: 8px 0 0 0;
}

.pad-8-left {
  padding: 0 0 0 8px;
}

.mar-16-top {
  margin: 16px 0 0 0;
}

.mar-16-left {
  margin: 0 0 0 16px;
}

.mar-16-right {
  margin: 0 16px 0 0;
}

.mar-16-bottom {
  margin: 0 0 16px 0;
}

.pad-16-right {
  padding: 0 16px 0 0;
}

.pad-16-bottom {
  padding: 0 0 16px 0;
}

.pad-16-top {
  padding: 16px 0 0 0;
}

.pad-16-left {
  padding: 0 0 0 16px;
}

.mar-24-top {
  margin: 24px 0 0 0;
}

.mar-24-left {
  margin: 0 0 0 24px;
}

.mar-24-right {
  margin: 0 24px 0 0;
}

.mar-24-bottom {
  margin: 0 0 24px 0;
}

.pad-24-right {
  padding: 0 24px 0 0;
}

.pad-24-bottom {
  padding: 0 0 24px 0;
}

.pad-24-top {
  padding: 24px 0 0 0;
}

.pad-24-left {
  padding: 0 0 0 24px;
}

.no-pad {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.left-float {
  float: left;
}

.right-float {
  float: right;
}

.no-float {
  float: none;
}

.with-pad {
  padding: 16px;
}

.no-action {
  pointer-events: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breakword {
  word-break: break-all;
}

.wrapword {
  word-wrap: break-word;
}

.strong {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
  font-weight: 300;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.center-content {
  justify-content: center;
}

.error-wrapper {
  .error-item {
    text-align: center;
    min-width: 240px;
  }
  @include smAndDown {
    div {
      width: 100%;
    }
  }
}

a,
img,
button {
  text-decoration: none;
  &:active,
  &:focus {
    outline: none;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.hide-important {
  display: none !important;
}

.show-important {
  display: block !important;
}

.btn:focus {
  outline: none !important;
}

// hack for disabling background for browser's styles for autofill state
// https://github.com/mui-org/material-ui/issues/14427#issuecomment-466054906
input:-webkit-autofill {
  transition-delay: 9999s;
  transition-property: background-color, color;
}

input,
textarea {
  caret-color: $primary;
}
