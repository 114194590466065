@import 'variables';

@mixin header1 {
  font-size: 60px;
  line-height: 80px;
  font-weight: bold;
  color: $black_19;
}

@mixin header2 {
  font-size: 48px;
  line-height: 64px;
  font-weight: bold;
  color: $black_19;
}

@mixin header3 {
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
}

@mixin header4 {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

@mixin header5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

@mixin header6 {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}

@mixin subtitle1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

@mixin subtitle2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
}

@mixin body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@mixin body2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

@mixin link {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: underline;
}

@mixin link2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: underline;
}

@mixin caption {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

@mixin overline {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 1.2em;
}

@mixin disclaimer {
  font-size: 10px;
  line-height: 18px;
  font-weight: 500;
}
