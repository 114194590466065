$staticUrlBase: 'https://assets.autonationmobileservice.com/web/';

// ----- ANMS VARIABLES ----- //

$road_flare_pink-15: #fcdce8;
$road_flare_pink-25: #f9c4d8;
$road_flare_pink-50: #f289b1;
$road_flare_pink: #e61464;

$celebration_pink-15: #f3dbe4;
$celebration_pink-25: #eac3d2;
$celebration_pink-50: #d687a5;
$celebration_pink: #ad104c;

$drive_pink-50: #f7b9d5;
$drive_pink-75: #f396c0;
$drive_pink-85: #f288b8;
$drive_pink: #ef73ab;

$midnight_blue-15: #dae0e5;
$midnight_blue-25: #c1ccd4;
$midnight_blue-50: #8499a8;
$midnight_blue: #0a3453;

$daylight_blue: #f2f4f6;
$cool_blue: #e7f2fa;
$medium_blue: #8db4d0;
$cool_gray: #69768c;
$new_car_blue: #2e64b5;
$link_blue: #0f5485;
$deep_blue: #212b36;

$anti_flash_white-15: #f5f5f5;
$gray_F3: #f3f3f3;
$anti_flash_white-25: #f6f6f6;
$anti_flash_white-50: #f9f9f9;
$light_silver: #eaeaea;
$light_gray: #d5d5d5;
$concrete_gray: #949494;
$carbon_fiber: #5c5c5c;
$gray_blues: #d2dadf;
$gray_blues_5: #f7f8f9;
$black_19: #191919;

$pavement: #333333;
$pavement2: #555555;
$pavement3: #f0f4f8;

// ----- GRADIENTS ----- //
$pink_gradient: linear-gradient(
  135deg,
  $celebration_pink 0%,
  $road_flare_pink 100%
);
$silver_gradient: linear-gradient(135deg, $light_silver 0%, $gray_F3 100%);
$blue_gradient: linear-gradient(135deg, $link_blue 0%, $new_car_blue 100%);

// ----- ERRORS ----- //
$red-15: #fecdca;
$red-25: #f97066;
$red-50: #f04438;
$red: #d92d20;

// ----- SUCCESS ----- //
$green-15: #a6f4c5;
$green-25: #32d583;
$green-50: #12b76a;
$green: #039855;
$green-00: #00b988;

// ------- PALETTE ------- //

$main-font-color: #3f3f3f;
$primary: $road_flare_pink;
$secondary: #ffb300;
$success: $green;
$danger: $red;

$default-font-family: Interstate, sans-serif;
$light-font-family: Interstate-Light, sans-serif;
$bold-font-family: Interstate-Bold, sans-serif;
$black-font-family: Interstate-Black, sans-serif;

// ----- OLD VARIABLES ----- //

$light_yellow: #ffd700;
$main_orange: #ffa700;
$light_orange: #ffb300;
$warning_orange: #ff6900;
$main_blue: #0061ff;
$light_blue: #007aff;
$white_blue: #fdfdff;
$dark_blue: #0046b9;
$water_blue: #0b8ed5;
$blue_shadow: #7ccfff;
$blueCC: #cce8f6;
$ice_blue: #e8f6fd;
$lighter_blue: #d7eaf7;
$white-blue: #f4fafe;
$white_blue_F4: #f4fafe;
$green: #31d89b;
$green_24: #24b48a;
$yellow: #ffe100;
$blue: #2c56dd;
$black: #001826;
$black_logo: #231f1f;
$navy: #0b2241;
$light-pink: #fcdce8;
$black_3F: #3f3f3f;
$black_49: #49576c;
$black_3C: #3c4859;
$gray_97: #979797;
$gray_1D: #1d1d1d;
$gray_33: #333333;
$gray_37: #373737;
$gray_3F: #3f3f3f;
$gray_66: #666666;
$gray_76: #767676;
$gray_99: #999999;
$gray_CD: #cdcdcd;
$gray_C1: #c1c1c1;
$gray_CC: #cccccc;
$gray_C6: #c6c6c6;
$gray_C9: #c9c9c9;
$gray_CB: #cbcbcb;
$gray_DE: #dedede;
$gray_D9: #d9d9d9;
$gray_DF: #dfdfdf;
$gray_EC: #ececec;
$gray_EF: #efefef;
$gray_EF1: #efeff1;
$gray_E0: #e0e0e0;
$gray_D8: #d8d8d8;
$gray_E0: #e0e0e0;
$gray_E1: #e1e1e1;
$gray_E8: #e8e8e8;
$gray_F5: #f5f5f5;
$gray_EA: #eaeaea;
$gray_F0: #f0f0f0;
$gray_F7: #f7f7f7;
$gray_F8: #f8f8fa;
$gray_FA: #fafafa;
$gray_FD: #fdfdfd;
$gray_2: #c2c8d6;
$gray_3: #ed9d00;
$gray_4: #f4fbfe;
$gray_f2: #f2f2f2;
$gray_BE: #bebebe;
$gray_B0: #b0b0b0;
$gray_EBED: #ebedf0;
$gray_e0f5: #e0f5ff;
$gray_DD: #dddddd;
$gray_DB: #dbdbdb;
$actionBoxBg: #eaeaea;
$white_blue: #fdfdff;
$snack_bg: #0a1d29;
$gradient_bg_gray: #f7f9fc;
$white: #ffffff;
$homepage_tan: #f4f1ef;
$homepage_text: #002042;
$homepage_blue: #00a8e2;
$homepage_grey: #54565a;
$homepage_sky: #a9d5ee;
$pink: #ffbaba;

//--APP--//
$font-family: Interstate, sans-serif;
$bold-font-family: Interstate-Bold, sans-serif;
$black-font-family: Interstate-Black, sans-serif;

// --------LAYOUT--------- //

$desktopColumns: 16;
$desktopColumnSize: 61.5px;
$desktopColumnGap: 24px;

@mixin body2 {
  font-family: $light-font-family;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
